import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GridIcons from "../components/content/gridicons"
import Helmet from "react-helmet"

import Subscribe from "../components/subscribe"
import { Menu, MenuCTA } from "../components/content/menu"

const IndexPage = ({ siteTitle, data }) => (
  <StaticQuery
    query={graphql`
      query HomeIndexPageQuery {
        site {
          siteMetadata {
            title
            url
            blogUrl
            appUrl
            getStartedUrl
            twitterUrl
            linkedInUrl
            feedbackUrl
            description
            homeDescription
            homeDescription2
            homeDescription3
            footerDescription
          }
        }
      }
    `}
    render={data => (
      <>
        {/* // Inside your component */}
        <Helmet
          bodyAttributes={{
            class: "home",
          }}
        />{" "}
        <Helmet>
          {/* <!-- IE10 viewport hack for Surface/desktop Windows 8 bug --> */}
          <link
            href="/theme/css/ie10-viewport-bug-workaround.css"
            rel="stylesheet"
          />

          {/* <!-- Custom styles for this template --> */}
          {/* <link href="/theme/css/cover.css" rel="stylesheet"/> */}
        </Helmet>
        <Layout>
          <SEO title={data.site.siteMetadata.description} />
          <div className="jumbotron page-header pt-6 pb-6 mb-6 mt-24" style={{backgroundColor: 'transparent'}}>
            <div className="container">
              <h1 className="display-32">{" "}</h1>
              <h2 className="display-32">
              {" "}
              </h2>
              <p>{" "}
              </p>{" "}
              {/* <p>
                <Menu />{" "}
              </p> */}
            </div>
          </div>


          <div className="site-wrapper cover-container">
            <div className="site-wrapper-inner">
              <div className="cover-container">
                {/* 
    <div className="masthead clearfix">
      <div className="inner">
        <h3 className="masthead-brand">Cover</h3>
        <nav>
          <ul className="nav masthead-nav">
            <li className="active"><a href="#">Home</a></li>
            <li><a href="#">Features</a></li>
            <li><a href="#">Contact</a></li>
          </ul>
        </nav>
      </div>
    </div> */}

                <div className="inner cover text-center">
                  {/* <div className="row"> */}
                  {/* <div className="col-md-12 col-md-offset-1b col-sm-12 text-center"> */}
                  <h1 className="cover-heading">
                    {/* {data.site.siteMetadata.description} */}
                    TRANSFORM. INNOVATE. GROW.
                  </h1>
                  <br />
                  {/* <p>
                    
                  </p> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* <h1 className="cover-heading">Cover your page.</h1> */}

                  <div className="container">
                    <div
                      id="carouselContent"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      <div className="carousel-inner" role="listbox">
                          <div
                          className="carousel-item active text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {/* {data.site.siteMetadata.footerDescription}  */}
                            
                          {/* Instantly review data privacy & data protection practices of tech companies. */}
                          Our purpose is to build solutions to create a better, smarter and safer world.
                          </p>
                        </div>

                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {/* {data.site.siteMetadata.footerDescription}  */}
                            
                          Instantly review data privacy & data protection practices of apps.
                          </p>
                        </div>
                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {/* {data.site.siteMetadata.footerDescription}  */}
                            
                          Instantly review data privacy & data protection practices of websites & social media.
                          </p>
                        </div>

                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {/* {data.site.siteMetadata.footerDescription}  */}
                            
                          Instantly review data privacy & data protection practices of IoT products & devices.
                          </p>
                        </div>
                        <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {/* {data.site.siteMetadata.footerDescription}  */}
                            
                          Instantly review data privacy & data protection practices of software & services.
                          </p>
                        </div>
                          <div
                          className="carousel-item  text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {/* {data.site.siteMetadata.footerDescription}  */}
                            
                          Streamlining procurement of technology solutions for business customers.
                          </p>
                        </div>
                          <div
                          className="carousel-item text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {/* {data.site.siteMetadata.footerDescription}  */}
                            Solving Data Protection Transparency for customers and providers.
                          </p>
                        </div>
                        <div
                          className="carousel-item text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {data.site.siteMetadata.homeDescription}
                          </p>
                        </div>
                      
                        <div
                          className="carousel-item text-center p-4b"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {data.site.siteMetadata.homeDescription3} 
                          </p>
                        </div>
                        {/* <div
                          className="carousel-item text-center p-4"
                          data-interval="10000"
                        >
                          <p className="lead"> 
                            {data.site.siteMetadata.description2} 
                          </p>
                        </div> */}
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#carouselContent"
                        role="button"
                        data-slide="prev"
                      >
                        {/* <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        /> */}
                        <span className="sr-only">Previous</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#carouselContent"
                        role="button"
                        data-slide="next"
                      >
                        {/* <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        /> */}
                        <span className="sr-only">Next</span>
                      </a>
                    </div>
                  </div>

                  <p>
                    <br />
                    {/* <Menu /> */}
                    {/* <MenuCTA /> */}
                    </p> <p>
                    <br /> 
                    <span className="text-muted">Learn More about how we can help your business</span>
                    
                    <br />
                  </p>
                  <p>
                    {/* <p className="lead"> */}
                    {/* <a href="/" className="btn btn-lg btn-default">
                      Learn more
                    </a> */}
                    {/* <Subscribe /> */}

                    <div
          id=""
          className="btn-group btn-group-md navbar-btn ml-auto mr-auto2 header-cta 
          "
          role="group"
          aria-label="Large button group"
        >{" "}
          <Link
            className=" nav-link btn btn-primary header-cta-signup  "
            to="/lets-talk/"
          >
           Let's Talk
          </Link>
        </div>
                    {/* </p>   */}
                    <br/>
                  </p>
             
              
                </div>
                </div>

                <div className="main-container"><section className="fullscreen image-bg parallax background-multiply">
    <div className="background-image-holder" style={{"-webkit-transform":"translate3d(0px, 530px, 0px)","-ms-transform":"translate3d(0px, 530px, 0px)","transform":"translate3d(0px, 530px, 0px)"}}>
      <img alt="image" className="background-image" src="/images/outerearth.jpg" />
    </div>
    <div className="container v-align-transform">
      <div className="row">
        <div className="col-sm-12">
          <center>
            <h1 className="thin2 mb0">{/*—ADVANCING INNOVATION TO BENEFIT HUMANITY AND THE WORLD.—*/}
              {/* TRANSFORM. INNOVATE. GROW. */}
            </h1></center>
          {/*     <br/>     <br/>
 <center>
<h2 className="thin2 mb0">SMART. SCALABLE. SECURE.
 </h2></center>
*/}
        </div>
      </div>
      <div className="row" style={{"-webkit-text-align":"center","text-align":"center"}}>
        <div className="col-sm-12">
          <br />
          {/*
               <div id="clientlogostripinner" style="margin-top:15px; width:inherit">

                      <div id="logo_banner" style="width:100%;"></div>

                </div>*/}
        </div>
      </div>
    </div>
  </section>
  <section id="approach">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <div className="feature" style={{"font-weight":"bold"}}>
            <h3>
              {/* Our purpose is to build solutions to create a better, smarter and safer world. */}
              </h3>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div className="row">
        {/*
      <div className="col-sm-3b text-center" style="display:none;">
          <div className="feature">
              <i className="icon fade-3-4 inline-block mb16 ti-light-bulb"></i>
              <h4>PRODUCTS</h4>
              <p>
                  Our purpose is to develop new game changing products and solutions to accelerate innovation & transform the world.
              </p>
               <div className="text-center">
                      <a className="btn btn-black mb32" href="/products/">Learn More</a>
              </div>
          </div>
      </div>
*/}
        <div className="col-sm-4 text-center">
          <div className="feature2">
            <i className="icon fade-3-4 inline-block2 mb64 ti-layout-grid3-alt pb-6 pt-6 mb64" />
            <br/><br/>
            <h5 className="mt64 ">SOLUTIONS</h5><br/><br/>
            <p>
              We help assess needs &amp; implement innovative solutions to help customers transform, innovate &amp; grow their organization.
            </p>
            <div className="text-center">
              <Link className="btn btn-black mb32   btn-outline-primary mb64  " to="/solutions/">Learn More</Link>
            </div>
          </div>
        </div>
        <div className="col-sm-4 text-center">
          <div className="feature2">
            <i className="icon fade-3-4 inline-block2 mb64 ti-loop pb-6 pt-6 mb64" /><br/><br/>
            <h5 className="mt64 ">CAPABILITIES</h5><br/><br/>
            <p>
              We provide superior capabilities to help organizations small and large build smart, scalable &amp; secure technology solutions.
            </p>
            <div className="text-center">
              {/*        <a className="btn btn-black mb32" href="/lets-talk/?service=capabilities">Learn More</a>*/}
              <Link className="btn btn-black mb32   btn-outline-primary mb64  " to="/capabilities/">Learn More</Link>
            </div>
          </div>
        </div>
        <div className="col-sm-4 text-center">
          <div className="feature ">
            <i className="icon fade-3-4 inline-block2 mb64 ti-eye pb-6 pt-6 mb64" /><br/><br/>
            <h5 className="mt64 ">LABS</h5><br/><br/>
            <p>
              We develop new products with cutting edge technologies to accelerate innovation, improve people's lives &amp; transform the world.
            </p>
            <div className="text-center">
              {/*        <a className="btn btn-black mb32" href="/lets-talk/?service=labs">Learn More</a>*/}
              <Link className="btn btn-black mb32   btn-outline-primary mb64  " to="/labs/">Learn More</Link>
            </div>
          </div>
        </div>
      </div>
      <div><small>{/*
<p>A bit about TheLeanFounder​</h4></p>
<p style=" ">We build transformative technologies to unlock the power of data on web, mobile, IoT & cloud platforms combined with AI, machine learning, data science and analytics. We aim to improve people's lives and help streamline organizations to create value, enhance productivity, improve bottom line, mitigate risks, be more agile & resilient.</p>*/}
        </small></div>
    </div>
  </section>
  {/* <footer className="footer-1 bg-dark">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-6"><div id="text-5" className="widget widget_text"><h6 className="title">UVISION</h6><hr />			<div className="textwidget"><p>&nbsp;</p>
              <p style={{"color":"white"}}>UVISION<br />
                1900 Campus Commons Drive, Suite 100, Reston, VA 20191, USA</p>
              <p><a href="tel:+17037292305">+703.729.2305</a><br />
                <a href="mailto:engage%40u%76%69s%69on.%63o">engage@uvision.co</a><br />
                <a className=" " href="/engage/">Engage Us</a></p>
            </div>
          </div></div><div className="col-md-3 col-sm-6" /><div className="col-md-3 col-sm-6" /><div className="col-md-3 col-sm-6" /><div className="clear" />      </div>
      <div className="row">
        <div className="col-sm-6">
          <span className="sub">
            Copyright © 2004-2020 UVISION. UVISION TECHNOLOGIES. 
            UVISION CONSULTING.   <br /><a href="/privacy-policy/">Privacy Policy</a>              </span>
        </div>
        <div className="col-sm-6 text-right">
          <ul className="list-inline social-list">
          </ul>
        </div>
      </div>
    </div>
    <a className="btn btn-sm fade-half back-to-top inner-link" href="#top">Top</a>
  </footer>	 */}
</div>
                <div>
                <div className="page-header2 text-center"><div className="cover-container2">
                  {/* <hr/><br/> */}
                {/* <h2>Simplifying data privacy trust &amp; transparency.</h2> */}
    {/* <p className="lead text-primary pb-2 pt-2">
        Free data protection intelligence on apps, sites &amp; providers for people with premium benefits for businesses.
      </p>  */}
                  
                  {/* <div className="col-xs-auto">
                    <img alt="TheLeanFounder" src="/images/b2bscreenshot1.png" className="center-block  img-responsive" />
                    
                    </div>      */}
	    {/*
                    <div className="container mr-auto ml-auto text-center ">
      <img alt="TheLeanFounder"   src="/images/b2bscreenshot1.png" className="pt-4 center-block img-responsive border border-light" />
    </div> */ } 
                    
                    </div></div>
                {/* <div className="container mr-auto ml-auto text-center ">

<hr/>           <br/>
      <img alt="TheLeanFounder" width="90%" src="/images/b2bscreenshot1.png" className="center-block img-responsive border border-light" />
    </div>  */}
    <div className="cover-container">

                <div className="mastfoot  text-center">
                  <div className="inner">
                    <div className={"copyright"}>
                      <p>
                        <small>
                          Copyright {new Date().getFullYear()}.{" "}
                          <a
                            href={data.site.siteMetadata.url}
                            title={data.site.siteMetadata.title}
                          >
                            {data.site.siteMetadata.title}
                          </a>
                          .{/* All rights reserved.   */}{" "}
                          <Link to="/privacy" title={"Privacy Policy"}>
                            Privacy Policy
                          </Link>
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </Layout>
      </>
    )}
  />
)

IndexPage.propTypes = {
  siteTitle: PropTypes.string,
}

IndexPage.defaultProps = {
  siteTitle: ``,
}

export default IndexPage
